import { Component, NgZone } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen'
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private router: Router,
    private zone: NgZone
    ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      await SplashScreen.hide({
        fadeOutDuration:200
      });
      if(Capacitor.isNativePlatform()){
        StatusBar.setBackgroundColor({color : '#6032e7'})
      }
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          const slug = event.url.split(".la").pop();
          if (slug) {
              this.router.navigateByUrl(slug);
          }
      });
  });
  }
}
